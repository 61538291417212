<template>
  <div
    data-hero
    class="relative flex justify-center overflow-hidden scrollbar-hide group"
    :class="[
      hideMobile ? 'hidden' : 'flex',
      hideDesktop ? 'lg:hidden' : 'lg:flex',
      alignMobile,
      alignDesktop,
      marginBottom,
      mobileSwipeable && 'min-w-[75vw] lg:min-w-0',
      aspectRatio,
      aspectRatioDesktop,
      backgroundColor && !backgroundColorHex ? backgroundColor : '',
    ]"
    :style="`background-color: ${backgroundColorCss}`"
  >
    <!-- LINK -->
    <mp-link
      v-if="$u(link) !== null"
      :to="$u(link)"
      aria-label="Hero link"
      class="absolute inset-0 z-10 block"
    />

    <!-- TEXT/LINKS -->
    <!-- HEADLINE TEXT -->
    <div
      v-if="headlines.length"
      class="absolute z-[5] flex flex-col w-full h-full gap-2.5 lg:gap-6 px-6"
      :class="[
        contentPosition(headlinePositionMobile, headlinePositionDesktop),
        paddingHorizontalDesktop,
        paddingVerticalDesktop,
        paddingVerticalMobile,
      ]"
    >
      <Headline
        v-for="(headline, headlineIndex) in headlines"
        :key="'headline-' + headlineIndex"
        :headline-type="headline.headlineType"
        :mobile-headline-size="headline.mobileHeadlineSize"
        :desktop-headline-size="headline.desktopHeadlineSize"
        :headline-color="headline.headlineColor"
        :eyebrow="headline.eyebrow"
        :headline="headline.headline"
        :lead="headline.lead"
        text-align="inherit"
        class="w-full"
      />
      <div
        v-if="links.length"
        class="flex flex-col items-start w-full gap-4 lg:gap-6"
        :class="[contentPosition(linksPositionMobile, linksPositionDesktop)]"
      >
        <Link
          v-for="(heroLink, heroLinkIndex) in links"
          :key="'heroLink-' + heroLinkIndex"
          v-bind="heroLink"
          class="text-sm font-normal tracking-widest lg:text-xl"
        >
          {{ heroLink.label }}
        </Link>
      </div>
    </div>

    <!-- BACKGROUND MEDIA -->
    <Vue2Image
      class="object-cover w-full h-full bg-lightGray block lg:hidden"
      :class="[lowerBrightness]"
      :src="image.filename || desktopImage.filename"
      :loading="isTopOfPage ? 'eager' : 'lazy'"
      :preload="isTopOfPage"
      :width="isFullWidth ? 2500 : 1000"
      :widths="isFullWidth ? [800, 1200, 1920, 2500] : [600, 1000]"
      :alt="alt || image.alt"
      :quality="60"
    />
    <Vue2Image
      class="object-cover w-full h-full bg-lightGray hidden lg:block"
      :class="[lowerBrightness]"
      :src="desktopImage.filename || image.filename"
      :loading="isTopOfPage ? 'eager' : 'lazy'"
      :preload="isTopOfPage"
      :width="isFullWidth ? 2500 : 1000"
      :widths="isFullWidth ? [800, 1200, 1920, 2500] : [600, 1000]"
      :alt="alt || image.alt"
      :quality="60"
    />
    <span
      v-if="hotspotProductData && hotspotProduct"
      class="
        absolute
        block
        z-10
        h-6
        w-6
        lg:w-5 lg:h-5
        bg-white
        rounded-full
        hover:bg-darkGray
        transition-colors
        cursor-pointer
        border-4 border-[#0000002e] border-solid
        lg:border-0 lg:outline
        outline-[#ffffff85]
        lg:outline-4
      "
      :style="`right: ${hotspotHorizontalPosition}%; bottom: ${hotspotVerticalPosition}%`"
      @click="showHotspotCardMobile = !showHotspotCardMobile"
    />
    <div
      v-if="hotspotProductData && hotspotProduct && !hideHotspotCard"
      class="absolute bottom-0 right-0 z-10 transition-all duration-300"
      :class="{
        'animate-hotspotVibration': vibratingHotspot,
        'translate-y-full': !showHotspotCardMobile,
        'translate-y-0': showHotspotCardMobile,
      }"
    >
      <HotspotCard
        v-if="hotspotProductData"
        :name="hotspotProductData.name"
        :product="hotspotProductData"
        :image="hotspotProductData.thumbnailImage"
        :url="hotspotProductData.url"
      />
    </div>
  </div>
</template>

<script>
import Vue2Image from '@made-people/vue2-image'
import { color } from '@made-people/centra-storyblok-nuxt-shared/lib/util/ColorHexCode'
import HotspotCard from './HotspotCard.vue'
import Headline from '~/components/headline/Headline'
import Link from '~/components/link/Link.vue'
import GridMixin from '~/mixins/GridMixin.js'


export default {
  name: 'Hero',
  components: {
    Headline,
    Link,
    Vue2Image,
    HotspotCard,
  },
  props: {
    ...GridMixin.props,
    aspectRatio: {
      type: String,
      required: false,
      default: 'aspect-[1/1]',
    },
    aspectRatioDesktop: {
      type: String,
      required: false,
      default: 'lg:aspect-[6/10]',
    },
    alt: {
      type: String,
      required: false,
      default: '',
    },
    hotspotVerticalPosition: {
      type: String,
      required: false,
      default: '0',
    },
    hotspotHorizontalPosition: {
      type: String,
      required: false,
      default: '0',
    },
    paddingVerticalDesktop: {
      type: String,
      required: false,
      default: 'lg:py-0',
    },
    paddingVerticalMobile: {
      type: String,
      required: false,
      default: 'py-0',
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '',
    },
    backgroundColorHex: {
      type: String,
      required: false,
      default: '',
    },
    hideMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    image: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    desktopImage: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    hideDesktop: {
      type: Boolean,
      required: false,
      default: false,
    },
    alignMobile: {
      type: String,
      required: false,
      default: 'text-left',
    },
    alignDesktop: {
      type: String,
      required: false,
      default: 'lg:text-left',
    },
    color: {
      type: String,
      required: false,
      default: null,
    },
    mobileSwipeable: {
      type: Boolean,
      required: false,
      default: false,
    },
    link: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    marginBottom: {
      type: String,
      required: false,
      default: 'mb-0',
    },
    headlines: {
      type: Array,
      required: false,
      default: () => [],
    },
    headlinePositionMobile: {
      type: String,
      required: false,
      default: 'top-left',
    },
    hideHotspotCard: {
      type: Boolean,
      required: false,
      default: false,
    },
    paddingHorizontalDesktop: {
      type: String,
      required: false,
      default: 'lg:px-0',
    },
    isTopOfPage: {
      type: Boolean,
      required: false,
      default: false,
    },
    headlinePositionDesktop: {
      type: String,
      required: false,
      default: 'top-left',
    },
    links: {
      type: Array,
      required: false,
      default: () => [],
    },
    linksPositionMobile: {
      type: String,
      required: false,
      default: 'bottom-left',
    },
    linksPositionDesktop: {
      type: String,
      required: false,
      default: 'bottom-left',
    },
    layoutMobile: {
      type: String,
      required: false,
      default: '',
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: null,
    },
    hotspotProduct: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      vibratingHotspot: false,
      hotspotProductData: null,
      showHotspotCardMobile: false,
    }
  },
  fetchOnServer: false,
  async fetch() {
    if (!this.hotspotProduct || !this.hotspotProduct.product) return
    const fetchProductFromCache = await this.$store.getters[
      'centra-product/getProductById'
    ](this.hotspotProduct?.product?.product)

    if (fetchProductFromCache) {
      this.hotspotProductData = fetchProductFromCache
    } else {
      await this.$store
        .dispatch(
          'centra-product/lookupProducts',
          this.hotspotProduct.product?.product
        )
        .then(() => {
          this.hotspotProductData = this.$store.getters[
            'centra-product/getProductById'
          ](this.hotspotProduct?.product?.product)
        })
    }
  },
  computed: {
    lowerBrightness: ({ link }) =>
      link.url !== '' || link.id !== ''
        ? 'brightness-75 group-hover:brightness-100 transition-all duration-300'
        : 'brightness-100',
    isFullWidth: ({ fullWidth }) => fullWidth || fullWidth === null,
    backgroundColorCss() {
      return color(this.backgroundColor, this.backgroundColorHex)
    },
  },
  methods: {
    contentPosition(mobileValue, desktopValue) {
      const mobile = {
        'top-left': 'justify-start items-start text-left',
        'top-center': 'justify-start items-center text-center',
        'top-right': 'justify-start items-end text-right',
        'center-left': 'justify-center items-start text-left',
        'center-center': 'justify-center items-center text-center',
        'center-right': 'justify-center items-end text-right',
        'bottom-left': 'justify-end items-start text-left',
        'bottom-center': 'justify-end items-center text-center',
        'bottom-right': 'justify-end items-end text-right',
      }
      const desktop = {
        'top-left': 'lg:justify-start lg:items-start lg:text-left',
        'top-center': 'lg:justify-start lg:items-center lg:text-center',
        'top-right': 'lg:justify-start lg:items-end lg:text-right',
        'center-left': 'lg:justify-center lg:items-start lg:text-left',
        'center-center': 'lg:justify-center lg:items-center lg:text-center',
        'center-right': 'lg:justify-center lg:items-end lg:text-right',
        'bottom-left': 'lg:justify-end lg:items-start lg:text-left',
        'bottom-center': 'lg:justify-end lg:items-center lg:text-center',
        'bottom-right': 'lg:justify-end lg:items-end lg:text-right',
      }
      return mobile[mobileValue] + ' ' + desktop[desktopValue]
    },
  },
}
</script>
